var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../utils/cssStyles';
//
import Iconify from '../iconify';
export default function DownloadButton(_a) {
    var onDownload = _a.onDownload;
    var theme = useTheme();
    return (_jsx(IconButton
    // color="inherit"
    , __assign({ 
        // color="inherit"
        onClick: onDownload, sx: {
            p: 0,
            top: 0,
            right: 0,
            width: 1,
            height: 1,
            zIndex: 9,
            opacity: 0,
            position: 'absolute',
            borderRadius: 'unset',
            // color: 'common.white',
            justifyContent: 'center',
            bgcolor: 'grey.800',
            color: 'common.white',
            transition: theme.transitions.create('opacity'),
            '&:hover': __assign({ opacity: 1 }, bgBlur({
                opacity: 0.64,
                color: theme.palette.grey[900],
            })),
        } }, { children: _jsx(Iconify, { icon: "eva:arrow-circle-down-fill", width: 24 }) })));
}
